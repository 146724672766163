import { Card } from 'antd';
import { groupBy, map } from 'lodash-es';
import { useBuildingConfig } from '../useBuildingConfig';
import { UsedShowWhatControlPanel } from './UsedShowWhatControlPanel';
import { useControlPanel } from './useControlPanel';
import { BlurComponent } from '../myInfo/BlurComponent';
import { ReadNavigation } from './ReadNavigation';

export default function CheckUsed() {
	const { changeTitle } = useBuildingConfig();
	changeTitle('核对水电读数');
	const { list, panel } = useControlPanel();
	const { controlPanel, isShowColdWater, isShowHotWater, isShowElectron } =
		UsedShowWhatControlPanel();

	return (
		<>
			<ReadNavigation />
			<Card>
				{panel}
				{controlPanel}

				<div className="flex flex-col">
					{map(
						groupBy(
							list.toSorted((a, b) => {
								return (
									Number.parseInt(a.roomNumber, 10) -
									Number.parseInt(b.roomNumber, 10)
								);
							}),
							(v) => {
								return v.roomNumber.slice(0, v.roomNumber.length - 2);
							},
						),
						(rooms, key) => {
							return (
								<div className="flex flex-col mb-3">
									<div>{key}楼</div>
									<div className="flex flex-row gap-6">
										<div className="w-28 shrink-0">
											<div className="">房间号</div>
											{isShowColdWater && <div>冷水读数</div>}
											{isShowHotWater && <div>热水读数</div>}
											{isShowElectron && <div>电量读数</div>}
										</div>

										<div className="flex flex-rows gap-6 overflow-x-auto">
											{rooms.map((room) => {
												return (
													<div key={room.roomNumber} className="w-16">
														<div className="">{room.roomNumber}</div>
														{isShowColdWater && (
															<BlurComponent>
																{room.coldWaterMeterReadingThisMonth.toNumber()}
															</BlurComponent>
														)}
														{isShowHotWater && (
															<BlurComponent>
																{room.hotWaterMeterReadingThisMonth.toNumber()}
															</BlurComponent>
														)}
														{isShowElectron && (
															<BlurComponent>
																{room.electricMeterReadingThisMonth.toNumber()}
															</BlurComponent>
														)}
													</div>
												);
											})}
										</div>
									</div>
								</div>
							);
						},
					)}
				</div>
			</Card>
		</>
	);
}
